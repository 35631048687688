import React from 'react'
import '../components/styles/404.css'
import { Button } from '../components/CustomButton'

const page404 = () => {
  return (
    <div id="main">
      <div class="fof">
        <h1>Error 404</h1>
        <br></br>
        <h1>Ups! One of us doesn't know how to type.</h1>
        <br></br>
        <Button Big="true" Round="true" to="/"> Go Home</Button>
      </div>
    </div>
  )
}

export default page404